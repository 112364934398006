import React, { useContext, useEffect, useState } from 'react'
import Stripe from 'stripe'
import { useNavigate } from 'react-router-dom'
import logo from "../../assets/logo_full.png"
import { Context } from '../../Context'
import { Nav } from '../Nav'
import { EditableInput } from './EditableInput'
import { db } from '../../firebase'
import { ref, get, child, set } from "firebase/database"
import { verifyBeforeUpdateEmail } from "firebase/auth";
import { userSignOut, resetPw } from '../Authentication/helpers'
import styles from "./Profile.module.scss"

export function Profile() {
  const {currentUser, auth} = useContext(Context) 
  const navigate = useNavigate()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [customer, setCustomer] = useState()
  const [subscription, setSubscription] = useState()
  const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY)

  const emailExp = new RegExp(/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i)
  const invalidEmailError = "Ingresa un email válido."
  const updateFirstName = (val) => {
    const r = ref(db, `users/${currentUser.uid}/firstName`)
    set(r, val)
  }

  const updateLastName = (val) => {
    const r = ref(db, `users/${currentUser.uid}/lastName`)
    set(r, val)
  }

  const updateEmailAddress = (val) => { //todo: prompt for reauthentication (recent login required)
    verifyBeforeUpdateEmail(currentUser, val)
    .then(() => alert("Revisa tu correo. Te enviamos un enlace de verificación a tu nuevo email."))
    .catch((error) => {
      if(error.code.includes("requires-recent-login")) {
        alert("Para verificar tu identidad antes de cambiar tu email, inicia sesión otra vez.")
        userSignOut(new Event("click"), auth, ()=>navigate("/"), ()=>navigate("/inicio"))
      }
      else  console.log(error)
    });
  }

  const handleResetPW = () => {
    alert("Te enviamos un enlace. ¡Revisa tu correo electronico!")
    navigate("/inicio")
    navigate(0)
  }
 
  useEffect(() => {
    if (currentUser) {
      //populate user info
      const dbRef = ref(db)
      const query = child(dbRef, `users/${currentUser.uid}`)
      get(query).then((snap) => {
        const userObj = snap.val()
        setFirstName(userObj.firstName)
        setLastName(userObj.lastName)
        setEmail(currentUser.email)
      })
      //get customer
      const fetchCustomer = async () => {
        await stripe.customers.search({
          query: `email:"${currentUser.email}"`,
        }).then((resp) => setCustomer(resp.data.length ? resp.data[0] : null));
      }
      fetchCustomer()
    }
    // eslint-disable-next-line
  }, [currentUser])

  useEffect(() => {
    if(customer) {
      async function fetchSubscription() {
        const customerId = customer.id
        await stripe.subscriptions.search({
          query: `status:'active'`,
        }).then(resp => setSubscription(resp.data.filter(s => s.customer === customerId)))
      }
      fetchSubscription()
    }
  // eslint-disable-next-line
  }, [customer])
  
  const displayPlan = (sub) => {

    const planType = sub.plan.interval === "month" && sub.plan.interval_count === 1? "Mensual" : "Trimestral"
    const planTimeUnit = sub.plan.interval === "month" && sub.plan.interval_count === 1? "mes" : "trimestre"
    const planCost = sub.plan.amount/100
    const getSpanishDate = (ts) => {
      const date = new Date(ts * 1000)
      return date.toLocaleString("es", {timeZone: "America/Bogota", dateStyle: 'long'}); 
    }
    const renewalDate = getSpanishDate(sub.current_period_end)

    return <><p>{`Plan ${planType} (USD ${planCost}/${planTimeUnit}). Se renueva el ${renewalDate}.`}</p>
    <p>Para gestionar tu plan, haz clic <span><a href="https://billing.stripe.com/p/login/cN25nH3zZ8KugTeeUU" target="_blank" rel="noreferrer">aquí</a></span>.</p></>
  }

  if(firstName) return (//todo:email editable input, password reset, account deletion
    <div className={styles.profile_wrapper}>
      <Nav />
      <div className={styles.logo_wrapper}>
        <img className={styles.logo} src={logo} alt="analista-visual-logo" width="100px" onClick={() => navigate("/")}/>
      </div>
      <div className={styles.main}>
        <div className={styles.profile_section}>
          <h3>Editar Información de Contacto</h3>
          <EditableInput label="Nombre" value={firstName} updateValue={updateFirstName} validityFn={(val) => val.length}/>
          <EditableInput label="Apellido" value={lastName} updateValue={updateLastName} validityFn={(val) => val.length}/>
          <EditableInput label="Email" value={email} updateValue={updateEmailAddress} validityFn={(val) => emailExp.test(val) && val.length} error={invalidEmailError}/> 
        </div>
        <div className={styles.profile_section}>
          <h3>Membresía</h3>
          <div className={styles.help}>
          {subscription &&  subscription.length ? displayPlan(subscription[0]) : <p>No tienes una suscripción activa. Haz click <span onClick={() => navigate("/activa")}>aquí</span> para suscribirte.</p>}
          </div>
        </div>
        <div className={styles.profile_section}>
          <h3>Soporte</h3>
          <div className={styles.help}>
            ¿Necesitas reestablecer tu contraseña? Haz clic <span onClick={(e) =>resetPw(e, auth, email, handleResetPW)}>aquí</span>.<br/><br/>
            Para cualquier otra consulta, escríbeme un correo a heilly@analistavisual.com.
          </div>
        </div>
      </div>
    </div>
  )
}

//todo: autoclose account (not urgent)