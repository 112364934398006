import React from 'react'
import { PencilSquareIcon, PlayIcon, BookmarkSquareIcon, StopIcon } from '@heroicons/react/24/solid'

import Editor from '@monaco-editor/react'
import { Loader } from './Loader'
import { Controls } from './Controls'

import styles from "./CodeEditor.module.scss"

export function CodeEditor({isLoading, isRunning, run, stop, save, submit, input, handleEditorChange}) {


    return (
    <div className={styles.editor_wrapper}>
        {isLoading && <Loader />}
        
        <Controls
        items={[
            {
            label: 'Ejecutar',
            icon: PlayIcon,
            onClick: run,
            disabled: isLoading || isRunning,
            hidden: isRunning
            },
            {
            label: 'Parar',
            icon: StopIcon, 
            onClick: stop, 
            hidden: !isRunning
            },
            {
            label: 'Guardar',
            icon: BookmarkSquareIcon,
            onClick: save,
            disabled: isLoading
            },
            {
            label: 'Evaluar',
            icon: PencilSquareIcon,
            onClick: submit,
            disabled: isLoading 
            }
        ]}
        />
        <Editor
            defaultLanguage="python"
            value={input}
            onChange={handleEditorChange}
            height="80%"
            theme="av-light"
        />
    </div>
  )
}
