import tn1 from "./courses/intro_visual/assets/wheel.png"
import tn2 from "./courses/abc_python/assets/bars.png"
import tn3 from "./courses/lista_para_listas/assets/scatter.png"
import tn4 from "./courses/bootcamp_logica/assets/turtle_beach.png"
import tn5 from "./courses/flujo_control/assets/sopa.png"
import tn6 from "./courses/intro_pandas/assets/pandasThumbnail.png"

export const ALL_COURSE_METADATA = [
    // {
    //     free: true,
    //     name:"tutorial",
    //     numPages: 1,
    //     title: "0. Tutorial"
    // },
    {
      name: "intro_visual",
      title: "1. Introducción Visual a la Programación",
      numPages: 11,
      free: true,
      description: "Aquí comienza todo. Por fin aprenderás qué es programar y escribirás tus primeras líneas de código. También será tu introducción a Turtle. Una herramienta que nos ayudará a hacer la programación más visual e intuitiva.",
      thumbnail: tn1
    },
    {
      name:'abc_python',
      title: "2. El ABC de Python",
      numPages: 13,
      free: false,
      description: "Este curso te enseña los conceptos básicos de programación en Python. Será como aprenderte el abecedario antes de poder formar palabras y oraciones. Veremos variables, tipos de datos y operaciones.",
      thumbnail: tn2
  },
]



export const COMING_SOON = [
    { 
        title: "3. Lista para las Listas",
        description: "En este curso te presento la idea de estructuras de datos. Nos enfocamos en las listas, sus propiedades, y métodos. Terminamos con una introducción a bucles iterando sobre las listas.",
        thumbnail: tn3
    },
    {
        title: "4. Bootcamp de Lógica",
        description: "Aprenderemos toda la lógica de programación que necesitarás. Adentraremos en diferentes operadores de comparación, identidad y membresía. Acabaras dominando los patrones if-else, i usaremos todo esto para filtrar listas.",
        thumbnail: tn4
    }, 
    {
        title: "5. Programas que Fluyen",
        description: "Este mini curso conecta todas las piezas del rompecabezas. Aprenderemos cómo usar listas, tipos de datos y sus operaciones para sacarle provecho a los bucles. Esto te dará control total del flujo de tus programas para que puedan hacer lo qu tú quieras 🚀.",
        thumbnail: tn5
    }, 
    {
        title: "6. Jugando con Pandas",
        description: "¡Pandas y los Famosos DataFrames! Esta es sin duda una de las herramientas escenciales que necesitas como analista de datos. Aprenderemos a crear DataFrames, a manipularlos, y realizar resumenes estadísticos de conjunto de datos.",
        thumbnail: tn6
    }, 
]


export const THEME_RULES = [
    //light gray
    {
        'foreground': "a0a1a7", 
        'token': 'comment'
    },
    {
        'foreground': "a0a1a7",
        'token': 'prolog'
    },
    {
        'foreground': "a0a1a7",
        'token': 'cdata'
    },
    //dark blue
    {
        'foreground': "383a42", 
        'token': 'bracket'
    },
    {
        'foreground': "383a42",
        'token': 'punctuation'
    },
    {
        'foreground': "383a42",
        'token': 'entity'
    },

    //orange
    {
        'foreground': 'b76b01', 
        'token': 'attr-name'
    },
    {
        'foreground': 'b76b01', 
        'token': 'class-name'
    },
    {
        'foreground': 'b76b01', 
        'token': 'number'
    },
    {
        'foreground': 'b76b01',
        'token': 'constant'
    },
    {
        'foreground': 'b76b01',
        'token': 'atrule'
    },
    //purple
    {
        'foreground': 'a626a4',
        'token': 'keyword'
    },
    //green
    {
        'foreground': '50a14f',
        'token': 'string'
    },
    {
        'foreground': '50a14f',
        'token': 'builtin'
    },
    {
        'foreground': '50a14f',
        'token': 'regex'
    },
    {
        'foreground': '50a14f',
        'token': 'char'
    },
    //blue
    {
        'foreground': '4078f2',
        'token': 'variable'
    },
    {
        'foreground': '4078f2',
        'token': 'operator'
    },
    {
        'foreground': '4078f2',
        'token': 'function'
    },
  ]

export const REACT_PY_PACKAGES = {
    official: ['pandas'],
    micropip: ['python-cowsay'],
}

//turtle and error setups
export let BRYTHON_SETUP = "from browser import document \noutput_ = document['brython-output'] \noutput_.clear() \ndocument['brython-error'].clear() \ndef print(*args): \n  if(isinstance(args, (type, tuple))): \n    str_args_ = []\n    for arg in args:\n      str_args_.append(str(arg))\n    output_ <= ''.join(str_args_)\n  else: \n    output_ <= args;\n  output_ <= '\\n' \n"
BRYTHON_SETUP += "import turtle \ncanvas_ = document['brython-canvas'] \ncanvas_.clear() \nturtle.set_defaults(canvwidth=400, canvheight=400, turtle_canvas_wrapper = canvas_) \n"
BRYTHON_SETUP += "import traceback \ndef error_print_(*args): \n document['brython-error'].clear() \n document['brython-error'] <= args \ntry:\n"
export const EXCEPT = "\n turtle.done()\nexcept: \n tb = traceback.format_exc() \n error_print_(tb)"
export let REACT_PY_SETUP = "turtleFuncs = ['forward','fd','backward','bk','back','right','rt','left','lt','goto','setpos','setposition','teleport','setx','sety','setheading','seth','home','circle','dot','stamp', 'clearstamp', 'clearstamps','undo','speed','position','pos','towards','xcor','ycor','heading','distance','degrees','radians','pendown','pd','down','penup','pu','up','pensize','width','pen','isdown','color','pencolor','fillcolor', 'begin_fill', 'end_fill','reset','clear','write','showturtle','st','hideturtle','ht','isvisible','shape','resizemode','shapesize','turtlesize','shearfactor','settiltangle','tiltangle','tilt','shapetransform','get_shapepoly','onclick','onrelease','ondrag']\n"
REACT_PY_SETUP += "screenFuncs = ['bgcolor','bgpic','clearscreen','resetscreen','screensize','setworldcoordinates','delay','tracer','update','listen','onkey','onkeyrelease','onkeypress','onclick','onscreenclick','ontimer','mainloop','done','mode','colormode','getcanvas','getshapes','register_shape','addshape','turtles','window_height','window_width']\n"
REACT_PY_SETUP += "class turtle:\n  def __init__(self):\n    def func(arg=0, font='', color='black', align='left', *args):\n      pass\n    for f in turtleFuncs:\n      setattr(self, f, func)\n  def Turtle():\n    return turtle()\n  class Screen():\n    def __init__(self):\n      def func(arg=0, *args):\n        pass\n      for f in screenFuncs:\n        setattr(self,f,func)\n  def done():\n    pass\n"

export const CONSOLE_TABS = [
    {
        name: 'output',
        label: 'Impresiones',
    }, 
    {
        name: 'canvas',
        label: 'Canvas',
    }, 
    {
        name: 'error',
        label: 'Errores',
    }, 
]

export const REWARDS_CONFIG = {
    spread:360,
    startVelocity: 30, 
    elementCount: 20, 
    elementSize: 25, 
    zIndex: 4, 
    colors: ["#C6E8D3", "#BBC0F2", "#E7EFEA", "#F3B6E2", "#FFFFFF"]
}
