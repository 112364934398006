import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from "./Landing.module.scss"
import logo from "../../assets/logo_full.png"
import banner from "../../assets/banner_pic.png"
import { Nav } from '../Nav'

export function Landing() {
  const navigate = useNavigate()

  return ( 
    <div className={styles.landing_wrapper}>
      <div className={styles.header}>
        <Nav />
        <div className={styles.title}>
            <img className={styles.logo} alt="logo-analista-visual" src={logo} onClick={() => navigate("/")}/>
            <h1>Cursos y talleres de programación creativa <br/> 💻🧠🎨</h1>
        </div>
      </div>
      <div className={styles.content}>
          <div className={styles.banner}>
            <h2>✨Practica python con lecciones visuales e interactivas✨</h2>
            <img alt="screenshots-de-worksheets" src={banner}/>
          </div>
          
          <div className={styles.screen_shots}></div>
          <div className={styles.details}>
          <span className={styles.floating}>Aprender programación con Analista Visual es para tí si...</span>
          <ul className={styles.details_wrapper}>
          <li className={styles.bullet}>
            <strong>💻 Eres principiante en la programación</strong><br/>Estás en el lugar correcto si quieres aprender a programar pero no tienes <b className={styles.kw}>ningún conocimiento previo </b>. Comienza de manera sencilla <b className={styles.kw}>sin tener que descargar o instalar nada</b>.
            </li>
            <br/>
            <li className={styles.bullet}>
            <strong>👀 Te consideras un persona creativa y visual</strong><br/> En estas lecciones interactivas usamos <b className={styles.kw}>visuales</b> para aterrizar los conceptos fundamentales y <b className={styles.kw} >divertirte mientras aprendes</b>. ¡Se te quedará todo grabado!
            </li>
            <br/>
            <li className={styles.bullet}>
            <strong>💸 Quieres conseguir un mejor trabajo</strong><br/>Estás buscando un nuevo reto y sabes que aprender programación te abre las puertas a <b className={styles.kw}>salarios altos</b> y <b className={styles.kw}>trabajo remoto</b>. 
            </li>
            <br/>
            
            <li className={styles.bullet}>
            <strong>🫂 Buscas sentirte bienvenida </strong><br/>
              Analista Visual es una comunidad <b className={styles.kw}>creada con mujeres en mente</b>. Valoramos la inclusión, la curiosidad, y la colaboración. Programar no es tan aterrador cuando estás acompañada. 
            </li>
            <br/>
            <li className={styles.bullet}>
            <strong>🕔 No tienes tiempo que perder</strong><br/>Tienes un trabajo y otras responsabilidades. ¡Te prometo que regresar a la universidad no es tu única opción! Con <b className={styles.kw}>sólo un par de horas al mes</b>, desarrollarás habilidades en programación que puedes aplicar desde el día uno.
            </li>
          </ul>
          </div>
          <button type="button" className={styles.primary_button} onClick={() => navigate("/registrate")}>¡Pruébalo Gratis!</button>
      </div>
    </div>
  )
}
