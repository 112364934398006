import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../../Context'
import { Navigate, useNavigate } from 'react-router-dom'
import { resetPw } from "../helpers"
import logo from "../../../assets/logo_full.png"
import styles from "./PasswordReset.module.scss"

export const PasswordReset = () => {
  const {auth, currentUser} = useContext(Context)
  const navigate = useNavigate()
  const [email, setEmail] = useState("") 
  const [invalidEmailError, setInvalidEmailError] = useState()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const handleSubmit = () => {
    alert("Te enviamos un enlace. ¡Revisa tu correo electronico!")
    navigate("/inicio")
    navigate(0)
  }

  //enable/disable submit
  useEffect(() => {
    if(email.length) {
      const emailExp = new RegExp(/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i)
      if(!emailExp.test(email)){
        setInvalidEmailError("Ingresa un email válido.")
        setIsSubmitDisabled(true)
      } else {
        setInvalidEmailError()
        setIsSubmitDisabled(false)
      }
    }
    else {
      setIsSubmitDisabled(true)
      setInvalidEmailError()
    }
  }, [email])

  if (currentUser) return <Navigate to="/menu" replace />
  return (
    <div className={styles.sign_in_wrapper}>
        <img className={styles.logo} src={logo} alt="analista-visual-logo" width="100px" onClick={() => navigate("/")}/>
        <form>
            <h1 className={styles.form_title}>¿Olvidaste tu contraseña?</h1>
            <p>No hay problema. Te enviamos un link para que crees una nueva.</p>
            <input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}></input>
            <span className={styles.error}> {invalidEmailError} </span>
            <button className={styles.primary_button }type="submit" disabled={isSubmitDisabled} onClick={(e) => resetPw(e, auth, email, handleSubmit)}>Enviar</button>
        </form>
        <div className={styles.help}> 
          ¿Aún no tienes una cuenta? {" "}
          <span onClick={() => navigate("/registrate")}>Regístrate</span> <br/> 
          <span onClick={() => navigate("/inicio")}>Inicia Sesión</span>
        </div>
    </div>
  )
}