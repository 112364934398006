import React, { useContext, useEffect, useState } from 'react'
import Stripe from 'stripe'
import { Nav } from '../../Nav'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../../Context'
import logo from "../../../assets/logo_full.png"
import styles from "./Payment.module.scss"

export function Payment() {
    const {currentUser} = useContext(Context)
    const [customer, setCustomer] = useState()
    const [subscription, setSubscription] = useState([])
    const navigate = useNavigate()
    const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY)

    useEffect(() => {
        if(currentUser) {
            const fetchCustomer = async () => {
                await stripe.customers.search({
                  query: `email:"${currentUser.email}"`,
                }).then((resp) => setCustomer(resp.data.length ? resp.data[0] : null));
              }
            fetchCustomer()
        }
    // eslint-disable-next-line
    }, [currentUser])

    useEffect(() => {
        if(customer) {
          async function fetchSubscription() {
            const customerId = customer.id
            await stripe.subscriptions.search({
              query: `status:'active'`,
            }).then(resp => setSubscription(resp.data.filter(s => s.customer === customerId)))
          }
          fetchSubscription()
        }
      // eslint-disable-next-line
      }, [customer])
    
  if (currentUser) {
    if (subscription && !subscription.length)  return (
        <div className={styles.payment_wrapper}>
            <Nav />
            <div className={styles.logo_wrapper}>
                <img className={styles.logo} src={logo} alt="analista-visual-logo" width="100px" onClick={() => navigate("/")}/>
            </div>
            <p>Elige un plan de suscripción:</p>
            <div className={styles.pricing_wrapper}>
                <div className={styles.price_container}>
                    <div className={styles.price_title}>Mensual</div>
                    <div className={styles.price_subtitle}><span className={styles.number}>USD 15</span>/mes</div>
                    <div className={styles.price_description}>
                    Acceso a todos los cursos en la plataforma de Analista Visual por 1 mes a partir de la compra. Se renueva automáticamente <b>cada mes</b>.
    
                    </div>
                    <button className={styles.buy_button} onClick={() => window.open(`https://buy.stripe.com/4gwg1hbI38pFg243ci?locale=es-419&prefilled_email=${currentUser.email}`)}>Comprar</button>
                  
                </div>
                
                <div className={styles.price_container}>
                    <div className={styles.price_title}>Trimestral</div>
                    <div className={styles.price_subtitle}><span className={styles.number}>USD 40</span>/trimestre</div>
                    <div className={styles.price_description}>Acceso a todos los cursos en la plataforma de Analista Visual por 3 meses a partir de la compra. Se renueva automáticamente <b>cada tres meses</b>.</div>
                    <button className={styles.buy_button} onClick={() => window.open(`https://buy.stripe.com/6oE4iz4fBgWbdTW8wB?locale=es-419&prefilled_email=${currentUser.email}`)}>Comprar</button>
                    <p>(Ahorro de $5 ✨)</p>
                </div>
            </div>
            <p>Cancela cuando quieras sin ningún problema.</p>
        </div>
      )
    else return navigate("/menu")
  }
  else return navigate("/")
}
