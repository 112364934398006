import React from 'react'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import styles from "./Preview.module.scss"
import { ProgressBar } from './ProgressBar'

export function Preview({type, item, isMember}) {
  const navigate = useNavigate()
  return (
    <div className={`${styles.preview_wrapper} ${!isMember && !item.free ? styles.locked_preview : ""}`} onClick={type === "course" ? () => navigate(`/aprende/${item.name}`) : null}>
        <div className={styles.preview_img}>
          <img alt={`thumbnail_curso_${item.name}`} src={item.thumbnail} />
        </div>
        <div className={styles.preview_content}>
            <div className={styles.title}>{item.title} {type==="todo" ? "🚧" : ""}{!isMember && type==="course" && !item.free ? <LockClosedIcon width="1em"/> : null}</div>
            <div className={styles.description}>{item.description}</div>
            <div className={styles.details}>{type === "course" ? <ProgressBar progress={item.progress} /> : item.time}</div>
        </div>
    </div>
  )
}
