import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from "firebase/auth"

export const  userSignUp = (e, auth, email, password, handleSignup, handleError) => {
    e.preventDefault()
    createUserWithEmailAndPassword(auth, email, password)
        .then(handleSignup)
        .catch((error) => {
            if (error.code.includes("already-in-use")) handleError("Este correo electrónico ya ha sido usado :( \nIntenta con otro.")
        })
}

export const userSignIn = (e, auth, email, password, handleSignin, handleError) => {
    e.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
        .then(() => handleSignin())
        .catch(() => {
            handleError("Email o contraseña incorrecta.")
        })
    
}

export const userSignOut = (e, auth, beforeSignout, handleSignout) => {
    e.preventDefault()
    beforeSignout()
    signOut(auth)
        .then(handleSignout)
        .catch((error) => console.log(error))
}

export const resetPw = (e, auth, email, handleReset) => {
    e.preventDefault()
    sendPasswordResetEmail(auth, email)
        .then(() => handleReset())
        .catch((error) => {
            console.log(error)
        })
}