import React, {useRef, useEffect} from 'react'
import * as d3 from 'd3' 
import styles from "./Loader.module.scss"

export function Loader() {
    const svgRef = useRef()
    useEffect(() => {
      const svg = d3.select(svgRef.current)
        .attr("width", 80)
        .attr("height",100)
      const [x,y] = [40,40]
      svg.append("path")
      .attr("transform", `translate(${x}, ${y})`)
      .attr("d", d3.arc().cornerRadius(15)({
          innerRadius: 25,
          outerRadius: 35,
          startAngle: 0,
          endAngle: 2*Math.PI
        })
      )
      .attr("class", styles.bg_path)
      const path = svg.append("path")
        .attr("transform", `translate(${x}, ${y})`)
        .attr("d", d3.arc().cornerRadius(15)({
            innerRadius: 25,
            outerRadius: 35,
            startAngle: -3*Math.PI / 4,
            endAngle: 0
          })
        )
        .attr("class", styles.top_path)
      
      animate()
      function animate () {
        const dur = 600
        path.transition()
          .attr("transform", `translate(${x}, ${y}) rotate(-120)`)
          .duration(dur)
          .attrTween("d", () => {
            const arcInterpolator = (t) => d3.arc().cornerRadius(15)({
              innerRadius: 25,
              outerRadius: 35,
              startAngle: -3*Math.PI / 4,
              endAngle: 2*Math.PI*t/3
            })
            return (t) => arcInterpolator(t)
          })
          .ease(d3.easeLinear)
          .transition()
          .attr("transform", `translate(${x}, ${y}) rotate(-240)`)
          .duration(dur)
          .ease(d3.easeLinear)
          .transition()
          .attr("transform", `translate(${x}, ${y}) rotate(0)`)
          .duration(dur)
          .ease(d3.easeLinear)
          .attrTween("d", () => {
            const arcInterpolator = (t) => d3.arc().cornerRadius(15)({
              innerRadius: 25,
              outerRadius: 35,
              startAngle: -3*Math.PI / 4,
              endAngle: 2*Math.PI*(1-t)/3
            })
            return (t) => arcInterpolator(t)
          })
          .on("end", animate)
      }


    }, [])

    return (
      <div className={styles.loader_wrapper}>
        <div className={styles.loader_container}>
          <svg
            ref={svgRef}
            className={styles.loader_svg}
          />
          <span>Preparando la plataforma...</span>
        </div>
      </div>
    )
  }