import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../Context'
import { Navigate, useNavigate } from 'react-router-dom'
import { userSignUp } from "../helpers"
import { ref, set } from "firebase/database"
import { db } from "../../../firebase"
import { sendEmailVerification, updateProfile } from 'firebase/auth'
import logo from "../../../assets/logo_full.png"
import styles from "./SignUp.module.scss"


export const SignUp = () => {
  const {auth, currentUser} = useContext(Context) 
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [invalidEmailError, setInvalidEmailError] = useState()
  const [usedEmailError, setUsedEmailError] = useState()
  const [weakPwError, setWeakPwError] = useState()
  const [matchingPwError, setMatchingPwError] = useState()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const navigate = useNavigate()

  const handleSignup = async (credentials) => {
    updateProfile(credentials.user, {
      displayName: firstName
    })
    const uid = credentials.user.uid
    const newUserRef = ref(db, `users/${uid}`)
    await set(newUserRef, {
      firstName: firstName, 
      lastName: lastName,
      credits: 0,
    })
    sendEmailVerification(credentials.user).then(() => alert("Revisa tu correo. Te enviamos un enlace de verificación."))
    navigate("/menu")
    navigate(0)
  }

  // uenable/disable submit button
  useEffect(() => {
    if (invalidEmailError || weakPwError || matchingPwError) return setIsSubmitDisabled(true)
    if (!firstName.length || !lastName.length || !email.length || !password.length || !password2.length) return setIsSubmitDisabled(true)
    setIsSubmitDisabled(false)
  }, [invalidEmailError, weakPwError, matchingPwError, firstName, lastName, email, password, password2])

  //update email error
  useEffect(() => {
    const emailExp = new RegExp(/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i)
    if(email.length && !emailExp.test(email)){
      setInvalidEmailError("Ingresa un email válido.")
    } else {
      setInvalidEmailError()
    }
  }, [email])

  //update invalid password error
  useEffect(() => {
  if(password.length){
    if(password.length >= 6 && password !== password.toLowerCase() && password !== password.toUpperCase()) {
      //todo: special char
      setWeakPwError()
      } else {
        setWeakPwError("Tu contraseña debe tener al menos 6 caracteres (mayúsculas, minúsculas y números).")
      }
    }
  }, [password])

  //update matching passwords error
  useEffect(() => {
    if(password.length && password2.length && password !== password2){
      setMatchingPwError("Las contraseñas no concuerdan.")
      } else {
        setMatchingPwError()
      }
      }, [password, password2])

  if (currentUser) return <Navigate to="/menu"  replace/>
  return (
    <div className={styles.sign_up_wrapper}>
      <img className={styles.logo} src={logo} alt="analista-visual-logo" width="100px" onClick={() => navigate("/")}/>
         <form>
           <h1 className={styles.form_title}>Comienza a practicar programación hoy</h1>
           <div className={styles.names}>
              <input className={styles.name_field} type="text" placeholder="Nombre" value={firstName} onChange={e => setFirstName(e.target.value)}></input>
              <input className={styles.name_field} type="text" placeholder="Apellido"  value={lastName} onChange={e => setLastName(e.target.value)}></input>
            </div>
            <input id="email_input" type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}></input>
            <input type="password" placeholder="Contraseña" value={password} onChange={e => setPassword(e.target.value)}></input>
            <input type="password" placeholder="Confirmar contraseña"  value={password2} onChange={e => setPassword2(e.target.value)}></input>
            <p className={styles.error}>
              {invalidEmailError} <br/> {weakPwError} <br/> {matchingPwError} <br/> {usedEmailError}
            </p>
            <button 
              type="submit" 
              disabled={isSubmitDisabled} 
              className={styles.primary_button} 
              onClick={(e) => userSignUp(e, auth, email, password, handleSignup, setUsedEmailError)}
            >
              Crear Cuenta
            </button> 
        </form>
        <div className={styles.help}> ¿Ya tienes una cuenta? <span onClick={() => navigate("/inicio")}>Inicia sesión </span></div>
    </div>
  )

  
}