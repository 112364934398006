import React from 'react'
import styles from "./Container.module.scss"

export function Container({title, children}) {
  if (children) return (
    <div className={styles.container_wrapper}>
        <h2>{title}</h2>
        {children}
    </div>
  )
}