import React from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';

import styles from "./Pagination.module.scss"


const List = styled('ul')({
  listStyle: 'none',
  padding: "0.5em",
  margin: "1em auto 0",
  display: 'flex',
  alignItems: 'center'
});

export function Pagination({count, currentPage, onClickPage, maxCompleted}) {
  //`count` is the total number of pages in a course
  //`currentPage` is my 0-based index used everywhere (db + elsewhere in codebase) except for this MUI Pagination element (and of course, the UI)
  //`onClickPage` is the callback for what happens when a page in this component changes (actual clicking on buttons is disabled, so this will only happen on clicking the `previous` and `next` buttons)
  //`maxCompleted` comes from the db. This is the highes page number that was "passed" in the lesson by the student. 0-based, as well
  
  const { items } = usePagination({
    count: count,
    onChange: onClickPage,
    page: currentPage + 1
  });

  //Remove first and last elements
  const pageButtons = [...items]
  const prevButton = pageButtons.shift()
  const nextButton = pageButtons.pop()
  

  return (
    <nav className={styles.pagination_wrapper}>
      <List>

        <button type="button" {...prevButton} className={`${styles.nav_button} ${styles.prev_button}`} disabled={ currentPage === 0}>
          Previa
        </button>
        <span className={styles.current_page_container}>
        <span className={styles.current_page}>{currentPage+1}</span>/{pageButtons[pageButtons.length-1].page}
        </span>
        <button type="button" {...nextButton} className={`${styles.nav_button} ${styles.next_button}`} disabled={currentPage === maxCompleted + 1}>
          {currentPage === count - 1 ? "Terminar" : "Siguiente"}
        </button>
      </List>
    </nav>
  );
}