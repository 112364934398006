import React from 'react'
import styles from "./ProgressBar.module.scss"

export function ProgressBar({progress}) {
  return (
    <div className={styles.progress_bar_wrapper}>
        <div className={styles.bar}>
            <div className={styles.background}>
                <div className={styles.fill} style={{width: `calc(min(${100*progress?.completed/progress?.total}%, 100%))`}}></div>
            </div>
        </div>
        <div className={styles.count}>{`${progress?.completed}/${progress?.total}`}</div>
    </div>
  )
}
