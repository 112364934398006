import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { userSignOut } from '../Authentication/helpers'
import { Context } from '../../Context'
import { db } from '../../firebase'
import { ref, get, child } from "firebase/database"
import styles from "./Nav.module.scss"

export function Nav() {
    const {auth, currentUser} = useContext(Context)
    const [user, setUser] = useState()
    const navigate = useNavigate()
    const handleSignout = () => {
        navigate(0)
    }

  useEffect(() => {
    if (currentUser) { //todo: abstract into helper
      const dbRef = ref(db)
      const query = child(dbRef, `users/${currentUser.uid}`)
      get(query).then((snap) => {
        const userObj = snap.val()
        setUser(userObj)
      })
    }
  }, [currentUser])

  if(currentUser) return (
    <div className={styles.nav_wrapper}>
      <div className={styles.course_button_wrapper}>
        <button type="button" className={styles.secondary_button} onClick={() => navigate('/menu')}>Cursos 📖</button>
      </div>
      <span id="rewardId"></span>
      <div className={styles.auth_button_wrapper}>
        <button className={styles.welcome} onClick={() => navigate("/perfil")}>Hola<strong>{`, ${user?.firstName}` || ""}</strong> ⚙️</button>
        <button type="button" className={styles.secondary_button} onClick={(e) => userSignOut(e, auth, () => navigate("/"), handleSignout)}>Cerrar Sesión</button>
      </div>
    </div>)
  return (
    <div className={styles.nav_wrapper}>
        <div></div>
        <div className={styles.auth_button_wrapper}>
          <button className={styles.secondary_button} type="button" onClick={() => navigate("/inicio")}>Inicia Sesión</button>
          <button className={styles.primary_button} type="button" onClick={() => navigate("/registrate")}>Regístrate</button>
        </div>
    </div>
  )
}
