import React, { useContext, useState, useEffect } from 'react'
import Stripe from 'stripe'

import { useParams, Navigate, useNavigate } from 'react-router-dom'
import { useReward } from 'react-rewards';
import { Pagination } from './Pagination';
import { CodePanel } from './CodePanel'
import { Context } from '../../Context'
import { Nav } from '../Nav'
import { db } from '../../firebase';
import {ref, get, child, update} from "firebase/database"
import { ALL_COURSE_METADATA, REWARDS_CONFIG } from '../../constants';

import styles from "./Classroom.module.scss"

export function Classroom() {
  const {currentUser} = useContext(Context) 
  const {courseName} = useParams()
  const navigate = useNavigate()

  const [customer, setCustomer] = useState()
  const [subscription, setSubscription] = useState()
  const [coursePages, setCoursePages] = useState()
  const [currentPage, setCurrentPage] = useState()
  const [startingCode, setStartingCode] = useState()
  const [maxCompletedPage, setMaxCompletedPage] = useState()
  const [lessonHTML, setLessonHTML] = useState()

  const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY);
  const { reward } = useReward('rewardId', 'confetti', REWARDS_CONFIG);

  useEffect(() => {
      //first get stripe customer object check membership/authorization
      if(currentUser){
        const fetchCustomer = async () => {
          await stripe.customers.search({
            query: `email:"${currentUser.email}"`,
          }).then((resp) => {
            if(!resp.data.length && !ALL_COURSE_METADATA.find(c => c.name === courseName).free ) navigate("/menu")
            setCustomer(resp.data.length ? resp.data[0] : null)
          });
        }
        fetchCustomer()
      }

     //import course content
     import(`../../courses/${courseName}/${courseName}.js`).then(resp =>{ 
      setCoursePages(resp.default)
    })

    

    if(currentUser && coursePages) {
      const dbRef = ref(db)
      const userCourseQuery = child(dbRef, `users/${currentUser.uid}/courses/${courseName}`)
      get(userCourseQuery).then(async (snap) => {
        //get user course data w/ pages done and saved code
        const userCourseData = await snap.val()
        //set the page
        setCurrentPage(userCourseData?.savedPage || 0) 

        //set the saved code
        setStartingCode(userCourseData?.savedCode || "")
        //set max completed page
        setMaxCompletedPage(() => {
          if (userCourseData && userCourseData.maxCompleted >= 0) return userCourseData.maxCompleted
          else return -1 
        })
      })
    }   
  // eslint-disable-next-line
  }, [currentUser, courseName, coursePages])

  useEffect(() => {
    if(customer) {
      async function fetchSubscription() {
        const customerId = customer.id
        await stripe.subscriptions.search({
          query: `status:'active'`,
        }).then(resp => 
          setSubscription(resp.data.filter(s => s.customer === customerId))
        )
      }
      fetchSubscription()
    }
  // eslint-disable-next-line
  }, [customer])

  useEffect(() => {
    //scrolll to top
    const contentContainer = document.getElementById("scrollable-content")
    if (contentContainer) {
      contentContainer.scrollTo(0,0)
    }
    //update content
    if(coursePages && currentPage >= 0){
      setLessonHTML(coursePages[currentPage].html)
      //if we're advancing to the next page, star with the new page's default code (saved code is now irrelevant)
      const dbRef = ref(db)
      const userCourseQuery = child(dbRef, `users/${currentUser.uid}/courses/${courseName}`)
      get(userCourseQuery).then(async (snap) => {
        //get user course data w/ pages done and saved page
        const userCourseData = await snap.val()
        if(userCourseData && currentPage === userCourseData.savedPage) {
          setStartingCode(userCourseData.savedCode)
        } 
      })
    }
    // eslint-disable-next-line
  }, [coursePages, currentPage])

  const onUnlockPage = () => {
    setMaxCompletedPage((currentMax) => {
      const newMax = Math.max(currentMax, currentPage)
      //update db
      const uid = currentUser.uid
      const courseRef = ref(db, `users/${uid}/courses/${courseName}`)  //each course as a name as a key and values for savedCode and savedPage
      update(courseRef, {
        maxCompleted: newMax,
        savedPage: newMax === coursePages.length -1 ? newMax : newMax+1,
        savedCode: ""
      })
      return newMax
    })
    reward()
  }

  if(!currentUser) return <Navigate to="/"/>
  if((ALL_COURSE_METADATA.find(c => c.name === courseName).free || (subscription && subscription.length)) && coursePages  && currentPage >= 0) return (
    <>
      <Nav />
      <div className={styles.course_title}>{ALL_COURSE_METADATA.find(c => c.name === courseName).title}</div>
      <div className={styles.classroom_wrapper} >
        <div className={styles.content_container}>
          <h2 className={styles.page_title}>{coursePages[currentPage].name}</h2>
          <div id="scrollable-content" className={styles.course_content}>
            {lessonHTML}
          </div>
          <Pagination 
            count={coursePages.length} 
            currentPage={currentPage} 
            onClickPage={(e, pageNum) => {
              if(pageNum -1 === coursePages.length) {
                navigate("/menu")
              }
              else {
              setCurrentPage(pageNum -1)
              }
            }} 
            maxCompleted={maxCompletedPage}
          />
        </div>
        
        {/* <div className={styles.editor_container}>
          <CodeEditor 
            isTurtle={coursePages[currentPage].isTurtle}
            checkCode={coursePages[currentPage].checkCode}
            startingCode={startingCode} 
            courseName={courseName} 
            currentUser={currentUser} 
            currentPage={currentPage} 
            answer={coursePages[currentPage].answer}
            handleUnlockPage={onUnlockPage} />
        </div> */}
        <CodePanel
          isTurtle={coursePages[currentPage].isTurtle}
          checkCode={coursePages[currentPage].checkCode}
          startingCode={startingCode} 
          courseName={courseName} 
          currentUser={currentUser} 
          currentPage={currentPage} 
          answer={coursePages[currentPage].answer}
          handleUnlockPage={onUnlockPage}
        />
      </div>
    </>
  )
}
