import React, {useState, useEffect} from "react"
import { useRoutes, Navigate } from 'react-router-dom';
import { auth } from "./firebase"
import { onAuthStateChanged } from "firebase/auth";
import { Context } from "./Context";
import styles from './App.module.scss';
import { Landing } from './components/Landing';
import { Dashboard } from "./components/Dashboard";
import { Classroom } from "./components/Classroom";
import { SignIn } from "./components/Authentication/SignIn";
import { SignUp } from "./components/Authentication/SignUp";
import { Footer } from "./components/Footer";
import { Profile } from "./components/Profile";
import { PasswordReset } from "./components/Authentication/PasswordReset";
import { Payment } from "./components/Dashboard/Payment"



//main app
export function App() {
  const [isLoading, setIsLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState()
  const handleAuthChange = (newUser) => {

    setCurrentUser(newUser)
    setIsLoading(false)
  }

  useEffect(() => {
      onAuthStateChanged(auth, handleAuthChange)
  }, [])



  const routes = useRoutes([
    {path: "/", element: <Landing /> }, 
    {path: "/inicio", element: <SignIn />},
    {path: "/reestablecer", element: <PasswordReset />},
    {path: "/registrate", element: <SignUp />}, 
    {path: "/activa", element: <Payment />}, 
    {path: "/menu", element: <Dashboard />},
    {path: "/aprende/:courseName", element: <Classroom />},
    {path: "/perfil", element: <Profile />},
    {path: "*", element: <Navigate to="/" replace />} 
  ])

  if (isLoading) return null //or loading spinner
  return (
    <div className={styles.app}>
      <Context.Provider value={{auth, currentUser}}>
      {routes}
      <Footer/>
      </Context.Provider>
    </div>
  );
}
