import { THEME_RULES } from '../../../constants'

export const defineTheme = (monacoObj) => {
    if(monacoObj) {
      
        monacoObj.editor.defineTheme('av-light', {
          base: 'vs',
          inherit: true,
          rules: THEME_RULES,
          colors: {
            'editor.background': '#fafafa',
          },
        })
        monacoObj.editor.setTheme('av-light')
      };
}

export function flashTryAgain(ref, styles) {
    ref.current.classList.add(styles.appearing_message)
    setTimeout(() => {
      ref.current.classList.remove(styles.appearing_message)
    }, 1000)
  }
