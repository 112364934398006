import React, { useState, useEffect, useContext} from 'react'
// import { useCalendlyEventListener } from "react-calendly";
import Stripe from 'stripe'
import { useNavigate } from 'react-router-dom'
import styles from "./Dashboard.module.scss"
import logo from "../../assets/logo_full.png"
import { Context } from '../../Context'
import { Container } from './Container'
import { Preview } from '../Preview'
import { Nav } from '../Nav'
import { db } from '../../firebase'
import { ref, get, child} from "firebase/database" //for classes, import runTracsaction
import { ALL_COURSE_METADATA, COMING_SOON } from '../../constants';

export function Dashboard() {
  const {currentUser} = useContext(Context)
  const [user, setUser] = useState()
  // const [userClasses, setUserClasses] = useState([])
  const [userCourses, setUserCourses] = useState()
  const [startedAndNewCourses, setStartedAndNewCourses] = useState()
  const [dashboardView, ] = useState('courses') //define setter to enable menu
  const [customer, setCustomer] = useState() //esisting sripe customer
  const [subscription, setSubscription] = useState([])
  const navigate = useNavigate()
  const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY);


  
  // const fetchClasses = (onComplete) => {
  //   //make call to Calendly API to get all active events for this user
  //   const options = {
  //     method: 'GET',
  //     headers: {'Content-Type': 'application/json', 'authorization': `Bearer ${process.env.REACT_APP_CALENDLY_TOKEN}`}
  //   };
  //   fetch(`https://api.calendly.com/scheduled_events?organization=${process.env.REACT_APP_ORGANIZATION_URI}&invitee_email=${currentUser.email}&status=active`, options)
  //     .then(response => response.json())
  //     .then(response => onComplete(response.collection))
  //     .catch(err => console.error(err));
  // }

  // const getSpanishDate = (ts) => {
  //   const date = new Date(ts)
  //   const formattedDate = date.toLocaleString("es", {timeZone: "America/Bogota", dateStyle: 'full',
  //   timeStyle: 'short',}); 
  //   const hour = date.getHours()
  //   return `${formattedDate} ${+hour >= 12 ? "PM" : "AM"} \n (hora Panamá/Colombia)`
  // }

  // const handleSchedule = async () => {
  //   //get classes from Calendly
  //   fetchClasses(setUserClasses)
  //   //remove a credit
  //   const uid = currentUser.uid
  //   const creditsRef = ref(db, `users/${uid}/credits`)
  //   await runTransaction(creditsRef, (prev) => prev - 1)
  // }

  //handle scheduleing and refresh dashboard
  // useCalendlyEventListener({
  //   onEventScheduled: () => {
  //     handleSchedule()
  //     navigate(0)
  //   }
  // })

  useEffect(() => {
    //get all user courses to display in dashboard
    if (currentUser) { 
      const dbRef = ref(db)
      const query = child(dbRef, `users/${currentUser.uid}`)
      get(query).then((snap) => {
        const userObj = snap.val()
        setUser(userObj)
        // fetchClasses(setUserClasses)
        const userCourseObjs = snap.val().courses ? Object.entries(snap.val().courses) : []
        const allUserCourses = userCourseObjs.map(d => {
          const matchingCourse = ALL_COURSE_METADATA.find(c => c.name === d[0])
          return {
            ...matchingCourse,
            name: d[0], 
            progress: {
              completed: d[1].maxCompleted + 1,
              total: matchingCourse?.numPages || 1
            }
          }
        })
        allUserCourses.sort((a,b) => a.title < b.title ? -1 : 1)
        setUserCourses(allUserCourses)
      })
      const fetchCustomer = async () => {
        await stripe.customers.search({
          query: `email:"${currentUser.email}"`,
        }).then((resp) => setCustomer(resp.data.length ? resp.data[0] : null));
      }
      fetchCustomer()
    }    
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])  

  
  useEffect(() => {
    if (userCourses) {
      const startedCourseNames = userCourses.map(c => c.name)
      const allCourses = ALL_COURSE_METADATA.map(c => {
        if(startedCourseNames.includes(c.name)) {
          //if user already started this course (entry exists in db)
          return userCourses.find(d => d.name === c.name)
        } else { //course info must come from metadata cosntant, number of completed pages is 0
          return {
            ...c,
            progress: {
              completed: 0,
              total: c.numPages
            }
          }
        }
      })
      setStartedAndNewCourses(allCourses)
    }
  }, [userCourses])

  useEffect(() => {
    if(customer) {
      async function fetchSubscription() {
        const customerId = customer.id
        await stripe.subscriptions.search({
          query: `status:'active'`,
        }).then(resp => setSubscription(resp.data.filter(s => s.customer === customerId)))
      }
      fetchSubscription()
    }
  // eslint-disable-next-line
  }, [customer])

  if(currentUser && user && subscription){ 
    const dashboardViewsContent = [
      {
        linkName: 'courses',
        elements: <>
          <div className={styles.message}>
           {subscription.length ? "" : <div><span className={styles.container_link} onClick={() => navigate("/activa")}>Activa tu membresía</span> para acceder el resto de los cursos</div>}
          </div>
          <Container children={
          <>
          <div className={styles.course_preview_container}>
            {startedAndNewCourses && startedAndNewCourses.map((d,i) => <Preview key={`class_preview_${i}`} type="course" item={d} isMember={!!subscription.length}/> )}
          </div>
          <hr/>
          <div className={styles.header_message}>Muy pronto tendremos más cursos disponibles 🤩</div>
          <div className={styles.course_preview_container}>
            {startedAndNewCourses && COMING_SOON.map((d,i) => <Preview key={`class_preview_${i}`} type="todo" item={d} isMember={false}/> )}
          </div>

          </>}
          />
        </>
      }
    ]

    return (
      <div className={styles.dashboard_wrapper}>
          <Nav />
          <div className={styles.logo_wrapper}>
              <img className={styles.logo} src={logo} alt="analista-visual-logo" width="100px" onClick={() => navigate("/")}/>
          </div>
          {/* <div className={styles.menu}>
              <div className={dashboardView==="courses" ? styles.selected_link : styles.link} onClick={() => setDashboardView('courses')}>Cursos</div>
              <div className={dashboardView==="classes" ? styles.selected_link : styles.link} onClick={() => setDashboardView('classes')}>Clases 1:1</div>
              <div className={dashboardView==="blog" ?    styles.selected_link : styles.link} onClick={() => setDashboardView('blog')}>Blog</div>
          </div> */}
          <div className={styles.main}>
            {dashboardViewsContent.find(db => db.linkName === dashboardView).elements}
          </div>
      </div>
    )
  }
}

