import React, { useState, useEffect, useRef } from 'react'
import { usePython } from 'react-py'
import { useMonaco } from '@monaco-editor/react'
import { db } from "../../../firebase"
import { ref, update } from 'firebase/database';

import { CodeEditor } from './CodeEditor'
import { Console } from './Console'

import { defineTheme, flashTryAgain } from "./helpers"
import { REACT_PY_PACKAGES, BRYTHON_SETUP, REACT_PY_SETUP, EXCEPT } from "../../../constants"

import styles from "./CodePanel.module.scss"
import consoleStyles from "./Console/Console.module.scss"

export function CodePanel({isTurtle, checkCode, startingCode, courseName, currentUser, currentPage, answer, handleUnlockPage}) {
  
    const [input, setInput] = useState("")
    const tryAgainRef = useRef()
    const monaco = useMonaco()

      useEffect(() => {
        setInput(startingCode ? startingCode.trimEnd(): "")
        defineTheme(monaco)    
      }, [startingCode, monaco])

      const {
        runPython,
        stderr,
        stdout,
        isLoading,
        isRunning,
        interruptExecution,
      } = usePython({ REACT_PY_PACKAGES })
    
      function run() {
        if(isTurtle) { //Brython run
          let script = document.getElementById("brython-script")
          if(!script) {
            script = document.createElement('script');
          }
          script.type = 'text/python'
          script.innerHTML = BRYTHON_SETUP + input.replace(/^/gm, ' '.repeat(1)) + EXCEPT
          script.async = true;
          const outputConsole = document.getElementById("brython-error")
          outputConsole.appendChild(script)
        } else { //react-py run
          runPython(REACT_PY_SETUP + input)
        }
      }
    
      function stop() {
        interruptExecution()
        // setShowOutput(false)
      }
    
      function save() {
        //actually save input to db
        const uid = currentUser.uid
        const courseRef = ref(db, `users/${uid}/courses/${courseName}`)  //each course as a name as a key and values for savedCode and savedPage
        update(courseRef, {
          savedCode: input,
          savedPage: currentPage
        })
      }

      async function reset() {
        //reset original input code
        setInput("")
    
        if(isTurtle) {
          //Run Brython, no input
          let script = document.getElementById("brython-script")
          if(!script) {
            script = document.createElement('script');
          }
          script.type = 'text/python'
          script.innerHTML = BRYTHON_SETUP+" pass"+EXCEPT
          script.async = true;
          const outputConsole = document.getElementById("brython-output")
          await outputConsole.appendChild(script);
        }
      }
    
      async function submit() {
        let isCorrect
        //check code
        if(checkCode) {
          //there must be an answer, and no errors for either compiler
            const answerLines = answer.replaceAll(" ", "").replaceAll("'", "\"").split("\n")
            const actualLines = input.replaceAll(" ", "").replaceAll("'", "\"").split(/\r\n|\r|\n/) //\r for new code line
            const matchedLines = actualLines.filter(act => answerLines.includes(act))
            isCorrect = JSON.stringify(matchedLines) === JSON.stringify(answerLines)
          
          if(isCorrect) {handleUnlockPage()}
          else {
            flashTryAgain(tryAgainRef, consoleStyles)
          }
        }
    
        //check output
        else {
          const outputConsole = document.getElementById("brython-output")
          const printedOutput = isTurtle ? outputConsole?.textContent : stdout //access inner text directly! There is state transition delay for some reason.
          isCorrect = String(printedOutput.replaceAll("'", "\"")).trimEnd() === String(answer.replaceAll("'", "\"")).trimEnd()   
          if(isCorrect) {handleUnlockPage()}
          else
            flashTryAgain(tryAgainRef, consoleStyles)
        }
      }
    
      function handleEditorChange(value, event) {
        setInput(value)
      }

      useEffect(() => {
        reset()
        // eslint-disable-next-line
      }, [currentPage])
      
  return (
    <div className={styles.code_panel_wrapper}>
        <CodeEditor 
            isLoading={isLoading} 
            isRunning={isRunning} 
            run={run}
            stop={stop}
            save={save}
            submit={submit}
            handleEditorChange={handleEditorChange}
            input={input} />
        <Console 
            initialSelectedOutput={isTurtle ? 'canvas' : 'output'}
            isTurtle={isTurtle} 
            stdout={stdout} 
            stderr={stderr}
            tryAgainRef={tryAgainRef}
        />
    </div>
  )
}