import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../../Context'
import { Navigate, useNavigate } from 'react-router-dom'
import { userSignIn } from "../helpers"
import logo from "../../../assets/logo_full.png"
import styles from "./SignIn.module.scss"

export const SignIn = () => {
  const {auth, currentUser} = useContext(Context)
  const navigate = useNavigate()

  const [email, setEmail] = useState("") 
  const [password, setPassword] = useState("")
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [wrongCredentialsError, setWrongCredentialsError] = useState()

  const handleSubmit = () => {
    navigate("/menu")
    navigate(0)
  }

  //enable/disable submit
  useEffect(() => {
    if(email.length && password.length) setIsSubmitDisabled(false)
    else setIsSubmitDisabled(true)
  }, [email, password])

  if (currentUser) return <Navigate to="/menu" replace />
  return (
    <div className={styles.sign_in_wrapper}>
        <img className={styles.logo} src={logo} alt="analista-visual-logo" width="100px" onClick={() => navigate("/")}/>
        <form>
            <h1 className={styles.form_title}>Inicia Sesión</h1>
            <input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}></input>
            <input type="password" placeholder="Contraseña" value={password} onChange={e => setPassword(e.target.value)}></input>
            <span className={styles.error}>{wrongCredentialsError}</span>
            <button className={styles.primary_button }type="submit" disabled={isSubmitDisabled} onClick={(e) => userSignIn(e, auth, email, password, handleSubmit, setWrongCredentialsError)}>Entrar</button>
        </form>
        <div className={styles.help}> 
          <p>¿Aún no tienes una cuenta? <span onClick={() => navigate("/registrate")}>Regístrate</span></p>
          <p><span onClick={() =>navigate("/reestablecer")}>Re-establece tu contrseña</span></p>
        </div>
    </div>
  )
}