import React, {useState} from 'react'
import styles from "./EditableInput.module.scss"

export function EditableInput({label, value, updateValue, validityFn, error}) {
    const [isEditing, setIsEditing] = useState(false)
    const [tempValue, setTempValue] = useState(value)
  return (
       <>
        <div className={styles.editable_input_wrapper}>
            <span className={styles.label}>{label}</span>
            <input className={styles.field} type="text" disabled={!isEditing} value={tempValue} onChange={e => setTempValue(e.target.value)}/>
            <button className={styles.button} type="button" onClick={() => {setIsEditing((prev) => !prev); setTempValue(value)}}>{isEditing ? "🗙" : "✎"}</button>
            {isEditing ? <button className={styles.button} type="button" disabled={!validityFn(tempValue)} onClick={() => {updateValue(tempValue); setIsEditing((prev) => !prev)}}>✔</button> : null}
        </div>
        <span className={styles.error}>{tempValue.length && !validityFn(tempValue) ? error : ""}</span>
        </>
    )
}
