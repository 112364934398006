import React, { useState } from 'react'
import { CONSOLE_TABS } from '../../../../constants'
import styles from "./Console.module.scss"

export function Console({initialSelectedOutput, isTurtle, stdout, stderr, tryAgainRef}) {
  const [selectedOutput, setSelectedOutput] = useState(initialSelectedOutput)
  //brython console outputs need to be watched and set (React does not detect as with react-py)
  const [brythonError, setBrythonError] = useState()

  // Observe for changes in the console
  const targetNode = document.getElementById(`console`);
  const config = { attributes: true, childList: true, subtree: true };
  const callback = (mutationList, obs) => {
      for (const mutation of mutationList) {
          if (mutation.type === "childList") {
              if (mutation.target.id === "brython-error") setBrythonError(mutation.target?.innerText || null)
          }
      };
  }
  const observer = new MutationObserver(callback);
  if (targetNode) observer.observe(targetNode, config);

  return (
    <div className={styles.console_wrapper} id="console">
        {/* Menu Links */}
        <div className={styles.output_menu}>
            {CONSOLE_TABS.map(t => <div 
            className={selectedOutput === t.name ? styles.selected_menu_link : styles.menu_link} 
            onClick={() => setSelectedOutput(t.name)} 
            key={`menu_link_${t.name}`}
            >
            {t.name === "error" && (stderr || brythonError) ? "Errores ⚠️" : t.label}
            </div>)}
        </div>
        {/* Try Again Message */}
        <div className={styles.try_again}><span ref={tryAgainRef}>Incorrecto. Intenta de nuevo.</span></div>
        
        {/* Console Elements */}
        {CONSOLE_TABS.map(t => <div
            id={`${isTurtle ? "brython" : "react-py"}-${t.name}`}
            className={styles.output}
            hidden={selectedOutput !== t.name}
            key={`output_div_${t.name}`}
        >
            {t.name === 'output' && !isTurtle && stdout}
            {t.name === 'error' && !isTurtle && stderr}
        </div>)}
    </div>
  )
}
